import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./card.css"
import Button from "./button"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "./layout"
import Seo from "./seo"
import img from '../images/hero.webp'
import star from '../images/star.svg'

function double(str) {
  if (str.length === 3) {
    return parseInt(str[0]) * 2 + "," + parseInt(str[2]) * 2
  } else {
    return str
  }
}
class ProductDetail extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {  };

  }
  
  render() {
    //border: "1px solid var(--color-secondary)",

    let item = this.props.pageContext.item

    let showIncluded = false
    let showDesc = false
    if (item.mattel_info) {
     
    }
    
    let imgObj = {
      img: "",
      width: 0
    }
    let arr = []
    if (item.img) {
      arr = JSON.parse(item.img)
    }
    for (let img in arr) {
      if (arr[img][0] > imgObj.width) {
        imgObj.img = img
        imgObj.width = arr[img][0]

      }
    }
    let description = item.desc || ""

    let numRating = parseInt(item.numRating)
    let avgRating = parseFloat(item.avgRating.replace(",", "."))
    let totalAvgRating = avgRating
    let totalNumRating = numRating

    if (avgRating === "N/A") {
      avgRating = 0
    }
    
    let title = ""
    let titleArray = item.title.split(/,|\-|–/)

    for (let k = 0; k < titleArray.length; k++) {
        const titleElement = titleArray[k];
        if (title.length < 22) {
            title += titleElement
        }
    }
    title = title.replace(/\s+/g, ' ');

    if (item.mattel_info) {
       // percentage 
      if (item.mattel_info.avgRating && item.mattel_info.numRating) {
        totalNumRating = numRating + parseInt(item.mattel_info.numRating)
        totalAvgRating = (((avgRating * numRating) + (parseFloat(item.mattel_info.avgRating) * parseInt(item.mattel_info.numRating))) / totalNumRating)
      }
    }
    let productDetails  = []
    let includeDetails = ["Altersempfehlung des Herstellers", "Zusammenbau nötig", "Batterien notwendig", "Batterien inbegriffen"]
    let conditionalDetails = [{
      title: "Fernsteuerung enthalten",
      category: "Rennbahnen"
    }]
    for (const key in item.productDetails) {
      if (Object.hasOwnProperty.call(item.productDetails, key)) {
        const detail = item.productDetails[key];
        if (productDetails.length < 6) {
          let include = false
          conditionalDetails.forEach(element => {
            if (key === element.title && item.categories.includes(element.category)) {
              include = true
            }
          });
          if (includeDetails.includes(key)) {
            include = true

          }
          if (include) {
            let title = key
            if ("Altersempfehlung des Herstellers" === key) {
              title = "Altersempfehlung"
            }
            productDetails.push({
              title: title,
              text: detail
            })
          }
        }
      }
    }

    
    return (
    <Layout>
      <Seo title="Carrera Bahnen und Zubehör" description={"Durchsuchen sie einfach unseren Katalog von Carrera Bahnen und Zubehör. Sie können ganz bequem über Amazon bestellen."}>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "${title}",
            "image": "${imgObj.img}",
            "description": "${description}",
            "brand": {
              "@type": "Brand",
              "name": "Carrera"
            },
            "audience": {
                "@type": "PeopleAudience",
                "suggestedGender": "unisex",
                "suggestedMinAge": 5,
                "age_group": "kids"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${totalAvgRating}",
              "reviewCount": "${totalNumRating}"
            },
            "keywords": "${item.categories}",
            "offers": {
              "@type": "Offer",
              "availability": "https://schema.org/InStock",
              "price": "${item.price.replace("€", "").replace(",", ".")}",
              "priceCurrency": "EUR"
            }
          }`}
        </script>

      </Seo>
      <div style={{width: "80%", margin: "20px auto", display: "flex"}}>
        <img src={imgObj.img} alt={item.title} style={{width: "35%", marginRight: 50}}/> 
        <div className=''>
          <h1 style={{marginBottom: 0, marginTop: 25}}>{item.title.replace(/Gesponserte Anzeige – /g, "").replace(/[#\?]/g, "").replace(/Sponsored Ad – /g, "")}</h1>
          <div className='' style={{display: "flex",  fontSize: "1.0em", marginTop: 10}}>
            <div style={{marginRight: 15}}>
            {"Anzahl der Bewertungen: " + totalNumRating} 

            </div>
            {totalAvgRating.toString() !== "NaN" &&
              <div className='row'>
                  <img src={star} style={{height: "15px", margin: "auto", marginRight: 5}}/>
                  {totalAvgRating.toFixed(1) + " / 5"} 
              </div>
            }
          </div>
          
          <div  style={{display: "flex", marginTop: 25}}>
            <div className='price' style={{display: "flex", marginRight: 25}}>
              <div className="rating" style={{fontSize: "18px", lineHeight: "39px", padding: "5px 15px", height: "auto"}}>{item.price}</div> 
            </div>
            <a className='amazon-button' href={item.url + "&tag=niklasoelze-21"} rel="nofollow" target={"_blank"} style={{padding: "5px 15px", display: "block", border: "1px solid var(--color-primary)", borderRadius: 2, fontSize: "18px", lineHeight: "37px"}}>Weiter zu Amazon</a>
          </div>
          
          {productDetails &&
            <div className='row' style={{justifyContent: "space-around", margin: "25px 0px"}}>
              {productDetails.map((detail, i) => (
                <div className='column' key={i} style={{textAlign: "center", fontSize: 14}}>
                    <div className='' style={{fontWeight: "bolder"}}>
                      {detail.title}
                    </div>
                    <div className=''>
                       {detail.text}
                    </div>
                </div>
              ))}
            </div>
          }
          
          {item.desc &&
            <div className='' style={{marginTop: 25}}>
              <div className=''>
                <b style={{fontSize: "1.2em"}}>Beschreibung</b>
              </div>
              <div className=''>
                {item.desc}
              </div>
            </div>
          }
          {showIncluded &&
            <div className='' style={{marginTop: 25}}>
              <div className=''>
                <b style={{fontSize: "1.2em"}}>Enthählt</b>
              </div>
              <div className=''>
                {item.mattel_info.includedDE}
              </div>
            </div>
          }
          
        </div>

      </div>
    </Layout>
    )
  }
 
}


export default ProductDetail
